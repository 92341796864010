import { useNavigate } from 'react-router-dom';

// mui components
import { Button, Container, Link, Stack, Typography } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { Image } from 'mui-image';

import { useTemplateInitialization } from './hooks/useTemplateInitialization';

// components
import { Dialog } from '../../components/Dialog';
import { APISpecificationDialogBody } from './components/APISpecificationDialogBody';

import './style.css';

/**
 * テンプレートページ
 */
export const Template: React.FC = () => {
  const {
    closeModal,
    initialized,
    loadTemplateError,
    notFoundTemplateError,
    open,
    openModal,
    setLoadTemplateError,
    template,
    templateUrl,
    user,
  } = useTemplateInitialization();

  const navigate = useNavigate();

  // テンプレートが取得できなかった場合に、エラーメッセージを表示
  if (loadTemplateError) {
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>プレビューを表示できませんでした。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={() => navigate('/')}>
            ダッシュボードに戻る
          </Button>
        </Container>
      </Stack>
    );
  }

  // テンプレートが見つからなかった場合、エラーメッセージを表示
  if (notFoundTemplateError) {
    return (
      <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
        <Stack direction="column" spacing={2}>
          <Typography>テンプレートが見つかりません。</Typography>
        </Stack>
        <Container style={{ textAlign: 'center' }}>
          <Button variant="contained" onClick={() => navigate('/')}>
            ダッシュボードに戻る
          </Button>
        </Container>
      </Stack>
    );
  }

  // 画面初期化中の場合、何も表示しない
  if (!initialized) return null;
  if (!template) return null;

  // テンプレートが見つかった場合、テンプレートの仕様を表示
  return (
    <Stack maxWidth="sm" mx="auto" spacing={2}>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h5" component="h2" fontWeight="bold">
          {template.name}
        </Typography>
      </Stack>

      <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="row">
          <Button variant="contained" onClick={openModal}>
            API仕様
          </Button>

          {/* API仕様モーダル */}
          <Dialog maxWidth="md" fullWidth open={open} handleClose={closeModal}>
            <APISpecificationDialogBody
              template={template}
              apiKey={user?.api_keys.length ? user.api_keys[0] : '{your_api_key}'}
            />
          </Dialog>

          <Stack flexDirection="row" alignItems="flex-end" ml={2}>
            <Link
              href="https://sheetx.app/docs/api#operation/post-sheets"
              target="_blank"
              underline="none"
              aria-label="API Document"
              alignItems="center"
              fontSize="small"
            >
              APIドキュメント
              <Launch fontSize="inherit" />
            </Link>
          </Stack>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        {/* テンプレートの状態によって、表示を切替 */}
        {template.status === 'available' && templateUrl && (
          <Image
            src={`${templateUrl}`}
            showLoading
            duration={0}
            style={{ border: '1px solid black' }}
            onError={() => {
              setLoadTemplateError(true);
            }}
            errorIcon={null}
          />
        )}
        {template.status === 'processing' && (
          <Typography>
            テンプレートは現在作成中です。
            <br />
            完了次第ご連絡いたします。
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
