/**
 * ファイル名を先頭5文字、最後5文字にトリミングする
 * @param fileName ファイル名
 * @returns "先頭5文字" + "..." + "最後5文字" + "拡張子"
 */
export const truncateFileName = (fileName: string | undefined) => {
  if (!fileName) return '';
  if (fileName.length <= 10) {
    return fileName;
  }
  const extensionIndex = fileName.lastIndexOf('.');
  if (extensionIndex > -1) {
    const nameWithoutExtension = fileName.slice(0, extensionIndex);
    const truncatedName = `${nameWithoutExtension.slice(0, 5)}...${nameWithoutExtension.slice(-5)}`;
    const extension = fileName.slice(extensionIndex);
    return truncatedName + extension;
  }
  return `${fileName.slice(0, 5)}...${fileName.slice(-5)}`;
};
