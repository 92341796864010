import { Box, BoxProps } from '@mui/material';
import { Status } from '../types/types';

type TemplateStatusBadgeProps = BoxProps & {
  status: Status;
};

const style = {
  display: 'inline-block',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: '600',
  minWidth: '20px',
  paddingLeft: '8px',
  paddingRight: '8px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  letterSpacing: '0.5px',
  lineHeight: '2',
  fontSize: '0.75em',
  borderRadius: '12px',
  cursor: 'default',
};

// statusごとのスタイル
const styleByStatus = {
  processing: {
    backgroundColor: 'rgba(247, 144, 9, 0.12)',
    color: 'rgb(181, 71, 8)',
  },
  available: {
    backgroundColor: 'rgba(16, 185, 129, 0.12)',
    color: 'rgb(11, 129, 90)',
  },
};

/**
 * テンプレートのステータスを示すバッジを表示するコンポーネント
 * @param status テンプレートのステータス
 */
export const TemplateStatusBadge: React.FC<TemplateStatusBadgeProps> = ({ status, ...props }) => (
  <Box {...props} component="span" sx={{ ...style, ...styleByStatus[status] }}>
    {status}
  </Box>
);
