import { Typography, Stack } from '@mui/material';
import { NoEntryTextAreaProps } from '../types/types';

/**
 * 試用未申込ページに表示するテキストエリア
 */
export const NoEntryTextArea: React.FC<NoEntryTextAreaProps> = ({ initialzed, error }) => {
  if (!initialzed) return null;
  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h5" fontWeight="bold">
        {error ? error.subject : 'お客様の環境を準備中です。'}
      </Typography>
      <Typography>{error ? error.message : '試用の申し込みがお済みでない方は、以下よりご登録ください。'}</Typography>
    </Stack>
  );
};
