import { Button } from '@mui/material';
import { FreeTrialButtonProps } from '../types/types';

const FORM_URL = 'https://share.hsforms.com/1suO3Pf7BQ8Gle7aoAake0Qnl90t';

/**
 * 試用申込ボタン
 */
export const FreeTrialButton: React.FC<FreeTrialButtonProps> = ({ email, initialized, error, ...rest }) => {
  if (error) return null;
  if (!initialized) return null;
  return (
    <Button href={`${FORM_URL}?email=${encodeURIComponent(email)}`} target="_blank" variant="contained" {...rest}>
      無料試用の登録を行う
    </Button>
  );
};
