import { useContext, useEffect, useState } from 'react';
import { TemplatesContext } from '../../../app/templatesProvider';
import { UserContext } from '../../../app/userProvider';

/**
 * ダッシュボードの初期化状態を管理するhook
 * - ユーザー情報とテンプレート一覧の取得状態を監視
 * - 両方のデータが取得できた時点で初期化完了とする
 */
export const useDashboardInitialization = () => {
  // ユーザーProviderを読込
  const { user } = useContext(UserContext);
  // テンプレートProviderを読込
  const { templates } = useContext(TemplatesContext);
  // 画面初期化完了を管理するstate
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized) return;
    // userとtemplatesが取得できたら初期化完了
    if (user !== undefined && templates !== undefined) {
      setInitialized(true);
    }
  }, [initialized, user, templates]);

  return {
    initialized,
    user,
    templates,
  };
};
