import { useContext, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Size, Orientation } from '../../../types/types';
import { LoadingContext } from '../../../app/loadingProvider';
import { TemplatesContext } from '../../../app/templatesProvider';
import { uploadSourceFile } from '../utils/uploadSourceFile';
import { truncateFileName } from '../utils/truncateFileName';
import { MANAGEMENT_API_IDENTIFIER } from '../../../config/env';

type TemplateCreateInputs = {
  templateName: string;
  sourceFile: File | null;
  size: Size;
  orientation: Orientation;
};

/**
 * 帳票テンプレート作成フォームのロジックを管理するhook
 * - 帳票テンプレート名、帳票イメージファイルの入力管理
 * - ファイルは10MB以下のみ許可
 * - テンプレートの作成とイメージのアップロードを実行
 */
export const useTemplateCreate = (onSuccess: () => void) => {
  const { getAccessTokenSilently } = useAuth0();
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const { createTemplate, deleteTemplate } = useContext(TemplatesContext);

  // 帳票イメージのデータを管理するInput用のref
  const fileInputRef = useRef<HTMLInputElement>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
  } = useForm<TemplateCreateInputs>({
    defaultValues: { templateName: '', sourceFile: null, size: 'A4', orientation: 'portrait' },
  });

  /**
   * ファイル選択時の処理
   * - 選択されたファイルをstateに保存
   * - 10MBを超える場合はエラーメッセージを表示
   */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors('sourceFile');

    const selected = e.target.files?.[0];
    if (selected) {
      if (selected.size > 1024 * 1024 * 10) {
        setError('sourceFile', { message: 'ファイルサイズは10MB以下にしてください' });
      }
      setValue('sourceFile', selected);
    }
  };

  /**
   * サイズと向き選択時の処理
   */
  const handleSizeAndOrientationSelectChange = (val: string) => {
    const [size, orientation] = val.split(',');
    setValue('size', size as Size);
    setValue('orientation', orientation as Orientation);
  };

  /**
   * テンプレート作成時の処理
   * - テンプレートデータをDBに保存
   * - 帳票イメージアップロード用RULを取得
   * - 帳票イメージをアップロード
   * - テンプレート一覧を更新
   * - モーダルを閉じる
   */
  const onSubmit: SubmitHandler<TemplateCreateInputs> = async (data: TemplateCreateInputs) => {
    const { templateName, size, orientation, sourceFile } = data;

    if (!sourceFile) {
      return setError('sourceFile', { message: 'ファイルを選択してください' });
    }

    try {
      showLoading('帳票テンプレートを作成しています...');

      const createTemplateRes = await createTemplate(templateName, size, orientation);
      if (createTemplateRes.isFailure()) {
        return alert('テンプレートの作成に失敗しました。\n時間をおいて再度お試しください。');
      }
      const template = createTemplateRes.value;

      showLoading('帳票イメージをアップロードしています...');

      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: MANAGEMENT_API_IDENTIFIER,
        },
      });

      const uploadSourceFileRes = await uploadSourceFile(sourceFile, template.template_id, accessToken, (percent) => {
        showLoading(`帳票イメージをアップロードしています... ${percent}%`);
      });
      if (uploadSourceFileRes.isFailure()) {
        await deleteTemplate(template.template_id);
        return alert('帳票イメージのアップロードに失敗しました。\n時間をおいて再度お試しください。');
      }

      onSuccess();

      return console.log('completed create template');
    } finally {
      hideLoading();
    }
  };

  return {
    control,
    errors,
    fileInputRef,
    handleSubmit,
    handleFileChange,
    handleSizeAndOrientationSelectChange,
    onSubmit,
    truncateFileName,
    watch,
    setValue,
    clearErrors,
  };
};
