import { useState } from 'react';

/**
 * テンプレート作成モーダルの状態を管理するhook
 * - モーダルの表示/非表示を制御
 * - 作成完了メッセージの表示/非表示を制御
 */
export const useTemplateCreateModal = () => {
  // テンプレート追加用モーダル表示用のstate
  const [open, setOpen] = useState(false);
  // テンプレート追加完了メッセージ表示用のstate
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  /**
   * モーダルを開く
   */
  const openModal = () => setOpen(true);

  /**
   * モーダルを閉じる
   */
  const closeModal = () => setOpen(false);

  /**
   * 作成完了メッセージを表示
   * - モーダルを閉じる
   * - 完了メッセージを表示
   */
  const showSuccess = () => {
    closeModal();
    setShowSuccessMessage(true);
  };

  /**
   * 作成完了メッセージを非表示
   */
  const hideSuccess = () => setShowSuccessMessage(false);

  return {
    open,
    showSuccessMessage,
    openModal,
    closeModal,
    showSuccess,
    hideSuccess,
  };
};
