import { Container, Stack } from '@mui/material';
import { FreeTrialButton } from './FreeTrialButton';
import { NoEntryTextArea } from './NoEntryTextArea';
import { useNoEntry } from '../hooks/useNoEntry';

/**
 * 試用未申込ページのContainer Component
 */
export const NoEntryContainer: React.FC = () => {
  const { initialized, error, email } = useNoEntry();

  return (
    <Stack direction="column" maxWidth="sm" mx="auto" spacing={4}>
      <NoEntryTextArea initialzed={initialized} error={error} />
      <Container style={{ display: error ? 'none' : 'block', textAlign: 'center' }}>
        <FreeTrialButton initialized={initialized} error={error} email={email} />
      </Container>
    </Stack>
  );
};
