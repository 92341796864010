import axios, { AxiosError } from 'axios';

// services
import { ErrorResponse, ProcessFailure, ProcessResult, ProcessSuccess } from '../../../utils/handleError';

// config
import { DASHBOARD_API_BASE_URL } from '../../../config/env';

/**
 * 帳票イメージをアップロード
 * - 帳票イメージをアップロードするための署名付きURLを取得
 * - 署名付きURLを使ってアップロード
 */
export const uploadSourceFile = async (
  file: File,
  templateId: string,
  accessToken: string,
  onUploadProgress: (percent: number) => void
): Promise<ProcessResult<boolean, ErrorResponse>> => {
  try {
    // 署名付きURLを取得
    const getSignedUrlResponse = await axios.post<{
      url: string;
    }>(
      `${DASHBOARD_API_BASE_URL}/sources/${templateId}/get-upload-url`,
      {
        file_name: file.name,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'content-type': 'application/json',
        },
      }
    );

    // 署名付きURLを使ってアップロード
    const formData = new FormData();
    formData.append('file', file);
    await axios.put(getSignedUrlResponse.data.url, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      // アップロード進捗を取得し、ローディング表示に利用する
      onUploadProgress: (progressEvent) => {
        if (progressEvent.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onUploadProgress(percentCompleted);
        }
      },
    });

    return new ProcessSuccess(true);
  } catch (err) {
    const axiosError = err as AxiosError;
    return new ProcessFailure(
      new ErrorResponse(uploadSourceFile.name, axiosError.response?.status, 'UPLOAD_SOURCE_FILE_ERROR', axiosError)
    );
  }
};
