import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../app/loadingProvider';

export const useLogin = () => {
  const navigate = useNavigate();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    try {
      showLoading();
      navigate('/', { replace: true });
    } finally {
      hideLoading();
    }
  });
};
