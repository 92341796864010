import { useContext, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingContext } from '../../../app/loadingProvider';
import { TOP_BASE_URL } from '../../../config/env';

export const useLogout = () => {
  const { logout } = useAuth0();
  const { showLoading, hideLoading } = useContext(LoadingContext);

  useEffect(() => {
    try {
      showLoading();
      logout({
        logoutParams: {
          returnTo: TOP_BASE_URL,
        },
      });
    } finally {
      hideLoading();
    }
  });
};
