import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '../../../app/userProvider';
import { LoadingContext } from '../../../app/loadingProvider';
import { NoEntryError, NoEntryResponse } from '../types/types';

/**
 * 試用未申込ページのロジックを提供するフック
 */
export const useNoEntry = (): NoEntryResponse => {
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const { user } = useContext(UserContext);

  const { showLoading, hideLoading } = useContext(LoadingContext);
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<NoEntryError | null>(null);

  useEffect(() => {
    if (error) return;
    if (initialized) return;

    try {
      showLoading();

      // プランがNoneでない場合はダッシュボードへ遷移
      if (user && user.plan !== 'None') {
        setInitialized(true);
        navigate('/', { replace: true });
      } else if (user && user.plan === 'None') {
        setInitialized(true);
      }
    } catch {
      setInitialized(true);
      setError({
        subject: 'エラーが発生しました。',
        message: '時間をおいて再度お試しください。',
      });
    } finally {
      hideLoading();
    }
  }, [navigate, user, showLoading, hideLoading, initialized, error]);

  return { initialized, error, email: auth0User?.email || '' };
};
