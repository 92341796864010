import { useEffect, useState } from 'react';
// config
import { SHEETX_API_BASE_URL } from '../../../config/env';
import { SnippetType, TabName, useAPISpecificationDialogProps } from '../types';

const dialogMarginTop = 32;
const dialogMarginBottom = 32;
const dialogTitleHeight = 64;
const tabsBarHeight = 48;

/**
 * 帳票テンプレート画面のAPI仕様ダイアログを管理するhook
 * - モーダルの表示/非表示を制御
 * - 作成完了メッセージの表示/非表示を制御
 */
export const useAPISpecificationDialog = (props: useAPISpecificationDialogProps) => {
  const { template, apiKey } = props;
  const [tabName, setTabName] = useState<TabName>('schema');
  const [tabPanelMaxHeight, setTabPanelMaxHeight] = useState<number>(0);

  /**
   * ウィンドウの高さが変更されたら、タブの高さを再計算する
   */
  useEffect(() => {
    const resize = () => {
      // タブパネルのmaxHeight = windowの高さ - ダイアログの上下のマージン - ダイアログのタイトル行の高さ - タブのバーの高さ
      const height = window.innerHeight - dialogMarginTop - dialogMarginBottom - dialogTitleHeight - tabsBarHeight;
      setTabPanelMaxHeight(height);
    };
    window.addEventListener('resize', resize);
    resize();

    return () => {
      window.removeEventListener('resize', resize);
    };
  });

  // タブを切り替える
  const changeTab = (event: React.SyntheticEvent, newTabName: TabName) => setTabName(newTabName);

  const createSchemaSnippet = () => {
    const replacementValueObj: { [key in string]: string } = {};
    template.replacements.forEach((replacement) => {
      replacementValueObj[replacement] = '';
    });

    return {
      response_type: '',
      sheets: [
        {
          template_id: '',
          page_number: 1,
          values: replacementValueObj,
        },
      ],
    };
  };

  /**
   * 帳票生成APIを呼び出すためのCurlコマンド文字列を生成
   */
  const createCurlSnippet = () => {
    const replacementValueObj: { [key in string]: string } = {};
    template.replacements.forEach((replacement) => {
      replacementValueObj[replacement] = `●●●`;
    });
    const headers = {
      'content-type': 'application/json',
      'x-api-key': apiKey,
    };
    const body = {
      response_type: 'url',
      sheets: [
        {
          template_id: template.template_id,
          page_number: 1,
          values: replacementValueObj,
        },
      ],
    };
    const headersString = Object.entries(headers)
      .map(([key, value]) => `-H "${key}: ${value}" \\`)
      .join('\n');
    const bodyString = JSON.stringify(body, null, 2)
      .split('\n')
      .map((line) => `  ${line}`) // Indent each line of the JSON body
      .join('\n');
    return `curl -X POST \\\n${headersString}\n-d '${bodyString.trim()}' \\\n${SHEETX_API_BASE_URL}/sheets`;
  };

  /**
   * スニペット名に応じたスニペット文字列を返す
   */
  const getSnippetText = (snippetType: SnippetType) => {
    if (snippetType === 'requestHeader') {
      return JSON.stringify({ 'x-api-key': '', 'content-type': '' }, null, 2);
    }

    if (snippetType === 'requestBody') {
      return JSON.stringify(createSchemaSnippet(), null, 2);
    }
    if (snippetType === 'curl') {
      return createCurlSnippet();
    }
    return '';
  };

  return {
    changeTab,
    createCurlSnippet,
    createSchemaSnippet,
    getSnippetText,
    tabName,
    tabPanelMaxHeight,
    tabsBarHeight,
  };
};
