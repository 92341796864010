import { useContext } from 'react';
import { Container, Dialog as MuiDialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

// providers
import { LoadingContext } from '../app/loadingProvider';

interface CustomDialogProps extends DialogProps {
  title?: string;
  closeModalOnBackdropClick?: boolean;
  handleClose: () => void;
}
/**
 * モーダルのヘッダなどの共通部分のコンポーネント
 *  - ex.
 *  -     const [open, setOpen] = useState(false);
 *  -     <Button onClick={() => setOpen(true)}>モーダルを開く</Button>
 *  -     <Dialog onClose={() => setOpen(false)}>{children}</Dialog>
 */
export const Dialog: React.FC<CustomDialogProps> = ({
  title,
  children,
  closeModalOnBackdropClick = true,
  handleClose,
  ...props
}) => {
  const { loading } = useContext(LoadingContext);

  return (
    <MuiDialog
      {...props}
      hideBackdrop={loading}
      onClose={(e, reason) => {
        if (reason === 'escapeKeyDown') {
          handleClose();
        }

        if (reason === 'backdropClick' && closeModalOnBackdropClick) {
          handleClose();
        }
      }}
    >
      <Container>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" pt={title ? 2 : 6}>
          {title && (
            <Typography variant="h5" fontWeight={700}>
              {title}
            </Typography>
          )}
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Stack>
      </Container>
      {children}
    </MuiDialog>
  );
};
