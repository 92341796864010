import { Link as RouterLink } from 'react-router-dom';

// mui components
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

// components
import { Close } from '@mui/icons-material';
import { CopyIcon } from '../../components/CopyIcon';
import { Dialog } from '../../components/Dialog';
import { TemplateStatusBadge } from '../../components/TemplateStatusBadge';
import { TemplateCreateForm } from './components/TemplateCreateForm';

// hooks
import { useDashboardInitialization, useTemplateCreateModal } from './hooks';

/**
 * ダッシュボードページ
 */
export const Dashboard: React.FC = () => {
  const { initialized, user, templates } = useDashboardInitialization();
  const { open, showSuccessMessage, openModal, closeModal, showSuccess, hideSuccess } = useTemplateCreateModal();

  if (!initialized) return null;

  return (
    <Stack maxWidth="sm" mx="auto" spacing={6}>
      {showSuccessMessage && (
        <Box sx={{ backgroundColor: 'rgba(16, 185, 129, 0.12)' }} p={2} position="relative">
          <Typography variant="body2">テンプレートを作成中です。完了次第ご連絡いたします。</Typography>
          <IconButton sx={{ position: 'absolute', right: '5px', top: '0' }} onClick={hideSuccess}>
            <Close />
          </IconButton>
        </Box>
      )}
      {/* 帳票テンプレート */}
      <Stack spacing={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="h2" fontWeight="bold">
            帳票テンプレート
          </Typography>
          <Button variant="contained" size="small" onClick={openModal}>
            新規作成
          </Button>
          <Dialog
            title="帳票テンプレートを作成"
            closeModalOnBackdropClick={false}
            maxWidth="xs"
            fullWidth
            open={open}
            handleClose={closeModal}
          >
            <TemplateCreateForm onCancel={closeModal} onSuccess={showSuccess} />
          </Dialog>
        </Stack>
        <Stack>
          {templates && templates.length > 0 ? (
            <TableContainer
              style={{ border: '1px solid rgb(230, 235, 241)', borderRadius: 5, backgroundColor: 'white' }}
            >
              <Table aria-label="templates table" size="small">
                <TableHead>
                  <TableRow sx={{ height: '64.5px' }}>
                    <TableCell>名前</TableCell>
                    <TableCell width="70px" align="center" style={{ paddingRight: 0 }}>
                      サイズ
                    </TableCell>
                    <TableCell
                      width="180px"
                      align="center"
                      style={{ paddingRight: 0 }}
                      sx={{ width: { sm: '180px', xs: '100px' } }}
                    >
                      template_id
                    </TableCell>
                    <TableCell width="120px" align="center">
                      ステータス
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((template) => (
                    <TableRow
                      key={template.template_id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell>
                        <Link component={RouterLink} to={`/templates/${template.template_id}`} underline="hover">
                          {template.name}
                        </Link>
                      </TableCell>
                      <TableCell align="center" style={{ paddingRight: 0 }}>
                        {template.size}
                        {template.orientation === 'portrait' && '縦'}
                        {template.orientation === 'landscape' && '横'}
                      </TableCell>
                      <TableCell style={{ paddingRight: 0 }}>
                        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
                          {template.template_id.substring(0, 15)}...
                          <CopyIcon text={template.template_id} />
                        </Stack>
                      </TableCell>
                      <TableCell align="center">
                        <TemplateStatusBadge status={template.status} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>現在、テンプレートはありません。</Typography>
          )}
        </Stack>
      </Stack>
      {/* APIキー */}
      <Stack spacing={2}>
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="h2" fontWeight="bold">
            APIキー
          </Typography>
        </Stack>
        <Stack>
          {user && user.api_keys.length > 0 ? (
            <TableContainer
              style={{ border: '1px solid rgb(230, 235, 241)', borderRadius: 5, backgroundColor: 'white' }}
            >
              <Table aria-label="API keys table" style={{ tableLayout: 'fixed' }} size="small">
                <TableHead>
                  <TableRow sx={{ height: '64.5px' }}>
                    <TableCell>APIキー</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.api_keys.map((apiKey) => (
                    <TableRow key={apiKey} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ wordBreak: 'break-word' }}>
                        {apiKey}
                        <CopyIcon text={apiKey} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>テンプレートの作成完了後、表示されます。</Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
